<template>
  <Dialog :msgBox="msgBox"></Dialog>
  <div v-loading="loading">{{msg}}</div>
</template>

<script>
import { store } from '@/store.js'
import { setToken } from "@/utils/auth";
import { login } from '@/api/login.js'
import Dialog from '@/components/ElDialog'

export default {
  name: "LoginPage",
  components: {
    Dialog
  },
  data(){
    return {
      appInfo: store.appInfo,
      loading: true,
      msg: 'loading...',
      msgBox: {
        open: false,
        title: '',
        content: ''
      }
    }
  },
  mounted() {
    let code = getQueryString("code");
    console.log('code', code)
    if (code) {
      login({code: code, state: '123'}).then((res) => {
        console.log('res', res)
        if (res && res.code === 200) {
          console.log('res.data.token', res.data.token)
          setToken(res.data.token)
          store.token = res.data.token
          let userInfoStr = JSON.stringify(res.data.userInfo);
          localStorage.setItem("userInfo", userInfoStr);
          window.location.href= '/'
        } else {
          console.log('error!')
        }

      }).catch(err => {
        this.msgBox.open = true
        this.msgBox.title = 'Error'
        this.msgBox.content = err
      }).finally(() => {
        this.loading = false
      });
    }
  }
}

function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
  let r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURI(r[2])
  return null
}
</script>

<style scoped>

</style>
