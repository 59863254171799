<template>
  <li class="flex items-start inline-block mt-2">
    <img class="h-10 w-10 rounded-full" :src="avatar" alt="">
    <p class="ml-2 my-1 p-2 mt-0 rounded-md border bg-white w-fit max-w-3/4 whitespace-normal">{{msg}}</p>
  </li>
</template>

<script>
export default {
  name: "ChatMessageUser",
  props: {
    msg: String,
    avatar: String,
  }
}
</script>

<style scoped>

</style>
