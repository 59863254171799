import { reactive } from 'vue'

export const store = reactive({
  robotRole: '',
  message: [],
  inputting: false,
  token: '',
  appInfo: {
    appId: process.env.VUE_APP_WX_APPID,
    loginRedirectUrl: process.env.VUE_APP_LOGIN_REDIRECT_URL,
    scope: 'snsapi_userinfo',
    state: ''
  },
  userInfo: {}
})
