<script>
import ChatRobot from './views/chat'
import Login from './views/login.vue'
import Profile from './views/Profile'
import NotFound from './views/404.vue'
import {getToken} from "@/utils/auth";
import {store} from '@/store'
import {CheckToken} from "@/api/login";
import Dialog from '@/components/ElDialog'

const routes = {
  '/': ChatRobot,
  '/login': Login,
  '/profile': Profile,
}
export default {
  data() {
    return {
      currentPath: window.location.hash,
      appInfo: store.appInfo,
      msgBox: {
        open: false,
        title: '',
        content: ''
      }
    }
  },
  components: {
    Dialog
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || NotFound
    }
  },
  created() {

  },
  mounted() {
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    // console.log('store.appInfo', store.appInfo)
    // console.log('process.env', process.env)
    // console.log('process.env.VUE_APP_BASE_API', process.env.VUE_APP_BASE_API)
    // console.log('process.env.VUE_APP_LOGIN_REDIRECT_URL', process.env.VUE_APP_LOGIN_REDIRECT_URL)
    // console.log('process.env.VUE_APP_WX_APPID', process.env.VUE_APP_WX_APPID)
    // console.log('token', token)
    // console.log('store.userInfo', store.userInfo)
    // console.log('store.userInfo.nickName', store.userInfo.nickName)

    this.checkToken()

    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })
  },
  methods: {
    checkToken() {
      const token = getToken();
      console.log('token', token)
      if (!token) {
        console.log('token not exist', token)
        if (window.location.hash !== '#/login') {
          this.gotoWxLogin();
        }
      } else {
        if (window.location.hash !== '#/login') {
          console.log('goto check token...')
          console.log('store.userInfo', store.userInfo)
          CheckToken({ openId: store.userInfo.openid, token}).then(res => {
            console.log('check token data', res)
            if (res.data === false) {
              this.gotoWxLogin();
            }
          }).catch(err =>  {
            console.log('check token err', err)
            if (err.code === 400) {
              this.gotoWxLogin();
            } else {
              this.msgBox.content = err.message;
              this.msgBox.open = true;
            }
          })
        }
      }
    },
    gotoWxLogin() {
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appInfo.appId}&redirect_uri=${encodeURIComponent(this.appInfo.loginRedirectUrl)}&response_type=code&scope=${this.appInfo.scope}&state=${this.appInfo.state}#wechat_redirect`;
      // this.currentPath = '#/login?code'
      window.location.href = url
    }
  }
}
</script>
<template>
  <component :is="currentView" />
  <Dialog :msgBox="msgBox"></Dialog>
</template>
