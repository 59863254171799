// import request from '@/utils/request'

// export function Request(message) {
//   return request({
//     url: '/chat',
//     method: 'post',
//     data: message
//   })
// }

const baseUrl = process.env.VUE_APP_BASE_API;

export async function Chat(messageList, auth) {
  return await fetch(`${baseUrl}api/v1/chat`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${auth}`,
    },
    body: JSON.stringify(messageList),
  });
}
