import { createApp } from 'vue'
import App from './App.vue'
import '@/styles/tailwind.css'
import 'github-markdown-css'
import '@/styles/index.css'
import {store} from "@/store";

const userInfoStr = localStorage.getItem("userInfo");

if (userInfoStr){
  store.userInfo = JSON.parse(userInfoStr)
  store.message.push({
    content: `你好，${store.userInfo.nickname}！我是基于GPT3.5模型的AI助理机器人，我叫智聊喵！你可以随便和我聊聊，如果想让我更精准的回复你的指令，请在下方设定一个角色。`,
    role: 'tip'
  });
}

console.log('store.userInfo', store.userInfo)

createApp(App).mount('#app')
