<template>
  <div ref="msg_cont" class="w-full flex-grow overflow-auto bg-gray-100">
    <ul ref="msg_cont_ul" class="p-2 flex flex-col text-left">
      <template v-for="item in store.message">
        <ChatMessageRobot v-if="item.role === 'assistant' || item.role === 'tip'" type="text" :msg="item.content" :key="item.index" />
        <ChatMessageUser v-if="item.role === 'user'" :avatar="avatar" type="text" :msg="item.content" :key="item.index" />
      </template>
<!--      <ChatMessageRobot-->
<!--        type="text"-->
<!--        msg="你好！我是基于GPT3.5模型的AI助理机器人，你可以随便和我聊聊，如果想让我更精准的回复你的指令，请在下方设定一个角色。"-->
<!--      />-->
<!--      <ChatMessageRobot-->
<!--        type="image"-->
<!--        msg="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=1000&h=1000&q=80"-->
<!--      />-->
<!--      <ChatMessageUser-->
<!--          avatar="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"-->
<!--          msg="Hello" />-->
<!--      <ChatMessageRobot type="text" msg="今天，YouTube博主Brad Lynch上传了一段关于介绍Quest 3的CAD设计图的视频。-->
<!--      据2022年5月泄露的一份路线图，Meta计划分别于2023年和2024年推出2款全新的Quest设备，代号为Stinson和Cardiff。-->
<!--      而这里，代号Stinson便是指Quest 3了。与高端Quest Pro不同之处在于，这款头显主要面向大众消费者。-->
<!--      Lynch称，由于距离上市还有一段时间，故最终成品可能会与此次公开的设计图有些变化。-->
<!--      不过在此前，他曾多次曝料过Quest Pro的头显细节，因此可信度没得说。" />-->
    </ul>
  </div>
</template>

<script>
import ChatMessageRobot from "./ChatMessageRobot";
import ChatMessageUser from "./ChatMessageUser";
import { store } from "@/store.js"

export default {
  name: "ChatMessage",
  components: {
    ChatMessageRobot,
    ChatMessageUser,
  },
  watch: {
    "store.message.length": function(val, oldVal) {
      console.log(val, oldVal)
      console.log('height', this.$refs.msg_cont.children[0].clientHeight)
      this.$nextTick(()=>{
        this.$refs.msg_cont.scroll(0, this.$refs.msg_cont.children[0].clientHeight)
      })
    },
    "store.message.inputting": function(val) {
      if (val) {
        this.toBottomFlag = setInterval(() => {
          const h = this.$refs.msg_cont.children[0].clientHeight;
          this.$refs.msg_cont.scroll(0, h)
        }, 100)
      } else {
        clearInterval(this.toBottomFlag);
      }
    }
  },
  data() {
    return {
      store,
      avatar: '/img/user.png',
      toBottomFlag: null,
      // avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
    }
  },
  created() {
    if (store.userInfo.headimgurl) {
      this.avatar = store.userInfo.headimgurl
    }
    console.log('store.userInfo', store.userInfo)
    console.log('avatar', store.userInfo.headimgurl)
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
