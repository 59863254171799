import request from '@/utils/request'

export function login({ code, state }) {
  return request({
    url: 'api/v1/WechatWebUser/Login',
    method: 'post',
    data: { code, state }
  })
}

export function CheckToken({ openId, token }) {
  return request({
    url: 'api/v1/WechatWebUser/checkToken',
    method: 'post',
    data: { openId, token }
  })
}

