<template>
  <div class="flex flex-col flex-none w-full border-t border-gray-300 bg-gray-100">
    <textarea
        rows="3"
        placeholder="请输入"
        v-model="userMsg"
        class="flex-1 h-auto outline-none rounded-none resize-none p-2 rounded-md border-gray-300 text-gray-600 bg-gray-100">
    </textarea>
    <div class="flex justify-end pr-3 pb-3">
      <button
          :disabled="sendBtnDisabled"
          @click="send"
          class="p-2 ml-2 rounded-md shadow-md bg-gray-600 text-gray-300 center disabled:opacity-40"
      >
        Send
      </button>
      <button class="p-2 ml-1 rounded-md shadow-md bg-gray-600 text-white text-gray-200">
        <ArchiveBoxXMarkIcon class="h-4 w-6 text-white inline"/>
      </button>
    </div>
  </div>
</template>

<script>
import { ArchiveBoxXMarkIcon } from '@heroicons/vue/24/outline'
import { store } from '@/store.js'
import { Chat } from '@/api/chat'
import {getToken} from "@/utils/auth";

export default {
  name: "ChatInput",
  components: {
    ArchiveBoxXMarkIcon
  },
  data() {
    return {
      sendBtnDisabled: true,
      store,
      userMsg: '',
      serverMsg: {
        id: null,
        object: null,
        created: null,
        model: null,
        usage: {
          prompt_tokens: null,
          completion_tokens: null,
          total_tokens: null,
        },
        choices: []
      }
    }
  },
  watch: {
    userMsg(val) {
      this.sendBtnDisabled = !val
    }
  },
  methods: {
    async send() {
      // Loading
      let sendMsg = this.userMsg
      this.userMsg = ''

      // 处理消息上下文
      let hasChatContext = this.store.message.find(it => it.role === 'user');

      if (store.message == null || store.message.length === 1 || !hasChatContext) {
        if (store.robotRole) {
          store.message.push({
            role: 'system',
            content: `你是一个${store.robotRole}`
          });
        } else {
          store.message.push({
            role: 'system',
            content: `你是一个很有帮助的助手。`
          });
        }
      }

      store.message.push({
        role: 'user',
        content: sendMsg
      });

      // 去掉消息中的 tip
      let msgCopy = Object.assign([], this.store.message)
      let delIndex = msgCopy.findIndex(it => it.role === 'tip');
      msgCopy.splice(delIndex, 1);

      const response = await Chat(msgCopy, getToken());

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const {status, body} = response;

      if (!body) {
        throw new Error('No data')
      }

      store.message.push({
        role: 'assistant',
        content: ''
      });

      const reader = body.getReader();
      await readStream(reader, status);
    }
  }
}

const decoder = new TextDecoder("utf-8");

async function readStream (reader, status) {
  const { done, value } = await reader.read();
  store.message.inputting = true;

  if (done) {
    reader.closed;
    store.message.inputting = false;
    return;
  }

  const char = decoder.decode(value);
  appendLastMessageContent(char);
  await readStream(reader, status);
}

function appendLastMessageContent(char) {
  store.message[store.message.length - 1].content += char
}



</script>

<style scoped>

</style>
