<template>
  <li class="self-end flex flex-row-reverse items-start inline-block ">
    <img class="h-10 w-10 ml-2 rounded-full" src="/img/logo_108_108.png" alt="">
    <div v-if="type === 'text'" v-html="htmlString()" class="mr-2 my-1 p-2 rounded-md bg-blue-200 w-3/4 max-w-prose leading-relaxed break-words markdown-body"></div>
    <p v-if="type === 'image'" class="mr-2 my-1 p-2 pt-1 rounded-md bg-blue-200 w-fit max-w-3/4">
      <img :src="msg" alt="" class="max-w-screen-2xl" />
    </p>
  </li>
</template>

<script>
import MarkdownIt from 'markdown-it'
import mdKatex from 'markdown-it-katex'
import mdHighlight from 'markdown-it-highlightjs'

export default {
  name: "RobotMessageRobot",
  props: {
    msg: String,
    type: String,
  },
  methods: {
    htmlString() {
      const md = MarkdownIt().use(mdKatex).use(mdHighlight)

      if (typeof this.msg === 'function') {
        return md.render(this.msg())
      } else if (typeof this.msg === 'string') {
        return md.render(this.msg)
      }
      return ''
    }
  }
}

// const htmlString = () => {
//   const md = MarkdownIt().use(mdKatex).use(mdHighlight)
//   const fence = md.renderer.rules.fence!
//       md.renderer.rules.fence = (...args) => {
//     const [tokens, idx] = args
//     const token = tokens[idx]
//     const rawCode = fence(...args)
//
//     return `<div relative>
//       <div data-code=${encodeURIComponent(token.content)} class="copy-btn gpt-copy-btn group">
//           <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"><path fill="currentColor" d="M28 10v18H10V10h18m0-2H10a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z" /><path fill="currentColor" d="M4 18H2V4a2 2 0 0 1 2-2h14v2H4Z" /></svg>
//             <div class="group-hover:op-100 gpt-copy-tips">
//               ${copied() ? 'Copied' : 'Copy'}
//             </div>
//       </div>
//       ${rawCode}
//       </div>`
//   }
//
//   if (typeof message === 'function') {
//     return md.render(message())
//   } else if (typeof message === 'string') {
//     return md.render(message)
//   }
//   return ''
// }
</script>

<style scoped>

</style>
