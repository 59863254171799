<template>
  <div class="w-full bg-gray-100">
    <div v-show="!isInput" class="flex justify-center p-2">
      <p v-if="roleName !==''">
        当前角色是<span class="text-orange-500 font-bold">{{roleName}}</span>
      </p>
      <button class="ml-2 text-blue-500" @click="setRole">设定AI助理的角色</button>
    </div>
    <div v-show="isInput" class="flex flex-col pt-3">
      <div class="px-3">
        <ul class="w-full overflow-x-auto inline float-left whitespace-nowrap">
          <li v-for="item in roleList"
              :key="item.index"
              @click="changeRole(item)"
              class="m-1 py-1 px-2 inline-block rounded-md bg-gray-200">
            {{item.role}}
          </li>
          <li class="m-1 py-1 px-2 inline-block rounded-md bg-blue-400 text-white">
            换一批
          </li>
        </ul>
      </div>
      <div class="flex justify-center p-1">
        <input ref="role_input"
               v-model="roleName"
               placeholder="请选择或者通过输入给AI助理设置一个角色，比如：医生"
               class="flex-1 h-auto outline-none rounded-none resize-none p-1 rounded-md border-gray-300 text-gray-600 bg-gray-100"
        >
        <button @click="saveRole" class="p-2 ml-2 rounded-md shadow-md bg-gray-600 text-white text-gray-200 center">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '@/store.js'

export default {
  name: "ChatRole",
  props: {

  },
  data() {
    return {
      store,
      roleName: "",
      isInput: false,
      roleList: [
        { role: "很有帮助的助手", tip: "我是你的AI小助手，向我提问吧" },
        { role: "旅游指南", tip: "我可以解答关于旅游相关知识，比如你可以问：我在北京，有哪些好玩的博物馆？" },
        { role: "宠物行为学家" , tip: "我会帮助你了解为什么宠物一直表现出某种行为，并想出相应的策略来帮助宠物进行调整。"},
        { role: "美容顾问" , tip: "爱美的小主，我可以解答你关于美容方面的问题。"}
      ]
    }
  },
  methods: {
    setRole() {
      this.isInput = true;
      // this.$nextTick(()=>{
      //   this.$refs.role_input.focus();
      // })
    },
    saveRole() {
      this.isInput = false;
    },
    changeRole(val) {
      this.roleName = store.robotRole = val.role;
      // let userMsg = this.store.message.find(it => it.role === 'user');
      this.store.message = [];
      this.store.message.push({
        role: "tip",
        content: val.tip
      });
      this.isInput = false;
    }
  }
}
</script>

<style scoped>
ul::-webkit-scrollbar {
  display: none;
}
</style>
