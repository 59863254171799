<template>
  <div
      class="bg-base-100 rounded-box mx-3 grid w-auto flex-shrink-0 place-items-center items-center gap-4 p-4 py-8 shadow-xl xl:mx-0 xl:w-full">
    <div class="online avatar">
      <div class="mask mask-squircle bg-base-content h-24 w-24 bg-opacity-10 p-px"><img
          :src="userInfo.headimgurl" width="94" height="94" alt="Avatar Tailwind CSS Component"
          class="mask mask-squircle"></div>
    </div>
    <div>
      <div class="text-center">
        <div class="text-lg font-extrabold">{{userInfo.nickname}}</div>
        <div class="text-base-content/70 my-3 text-sm">
          Welcome to APPINAI
        </div>
      </div>
      <div class="mt-2 text-center">
        <div class="badge badge-ghost">APP</div>
        <div class="badge badge-ghost">IN</div>
        <div class="badge badge-ghost">AI</div>
      </div>
      <div class="m-5">
        <button class="btn btn-ghost w-full my-1" @click="goHome">返回</button>
        <button class="btn btn-ghost w-full my-1" @click="logout">退出登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import {removeToken} from "@/utils/auth";
import {store} from "@/store";

export default {
  name: "UserProfile",
  data() {
    return {
      userInfo: store.userInfo
    }
  },
  mounted() {
    console.log('profile window.location.hash', window.location.hash)
  },
  methods: {
    goHome() {
      if (window.location.hash) {
        window.location.hash = '#/'
      } else {
        window.location.href='/'
      }
    },
    logout() {
      removeToken()
      window.location.href = '/'
    },
  }
}
</script>

<style scoped>

</style>
