<template>
  <div className="m-auto flex flex-col content-between w-screen h-screen text-base">
    <!--    <PageHeader/>-->
    <div class="navbar bg-base-100 py-2">
      <div class="flex-none">
        <label class="btn btn-square btn-ghost" for="my-drawer-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               class="inline-block w-5 h-5 stroke-current">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </label>
      </div>
      <div class="flex-1">
        <a href="https://www.appinai.com" target="_blank" class="btn btn-ghost normal-case text-xl px-1">APPINAI</a>
        <span class="text-xl text-transparent font-extrabold bg-clip-text bg-gradient-to-r from-green-400 to-blue-600">智聊喵</span>
      </div>
      <label tabindex="0" class="btn btn-ghost btn-circle avatar">
        <div class="w-8 rounded-full">
          <img :src="userInfo.headimgurl" @click="gotoProfile"/>
        </div>
      </label>
    </div>

    <div class="drawer drawer-mobile">
      <input id="my-drawer-2" type="checkbox" class="drawer-toggle"/>
      <div class="drawer-content h-auto flex flex-col content-between items-center justify-center">
        <!-- Page content here -->
        <!-- <label for="my-drawer-2" class="btn btn-primary drawer-button lg:hidden">Open drawer</label>-->

        <ChatMessage/>
        <ChatRole/>
        <ChatInput/>
      </div>
      <div class="drawer-side">
        <label for="my-drawer-2" class="drawer-overlay"></label>
        <ul class="menu p-4 w-80 bg-base-100 text-base-content">
          <!-- Sidebar content here -->
          <li>
            <a href="https://www.appinai.com" target="_blank">
              <img class="w-8" src="http://appinai.com/wp-content/uploads/2023/03/logo-dark_collapsed.png">
              AI导航资讯
            </a>

          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from "./components/PageHeader";
import ChatMessage from "./components/ChatMessage";
import ChatInput from "./components/ChatInput";
import ChatRole from "./components/ChatRole";
import {store} from "@/store";

export default {
  name: 'ChatRobot',
  components: {
    // PageHeader,
    ChatMessage,
    ChatRole,
    ChatInput,
  },

  data() {
    return {
      userInfo: store.userInfo
    }
  },
  setup() {
    return {}
  },
  mounted() {
    console.log('chat window.location.hash', window.location.hash)

  },
  methods: {

    gotoProfile() {
      window.location.hash = '#/profile'
    }
  }
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 80px;*/
}
</style>
